import * as _functionBind2 from "function-bind";
var _functionBind = _functionBind2;
try {
  if ("default" in _functionBind2) _functionBind = _functionBind2.default;
} catch (e) {}
var exports = {};
var call = Function.prototype.call;
var $hasOwn = Object.prototype.hasOwnProperty;
var bind = _functionBind;

/** @type {import('.')} */
exports = bind.call(call, $hasOwn);
export default exports;